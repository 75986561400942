<template>
  <div
    class="why-switch-insurance-with-bionic item-container"
    :class="itemType"
  >
    <div class="item-image">
      <LazyImage :src="imgSrc" alt="" />
    </div>
    <div class="item-title">
      <h4 :class="[getPartner ? 'color-black' : '']">
        {{ itemTitle }}
      </h4>
    </div>
    <div class="item-body">
      <p>{{ itemText }}</p>
    </div>
  </div>
</template>

<script charset="utf-8">
import { mapGetters } from 'vuex'
import LazyImage from '@/components/performance/LazyImage'

export default {
  components: {
    LazyImage
  },
  props: {
    imgSrc: {
      type: String,
      default: 'Lorem ipsum image source'
    },
    itemTitle: {
      type: String,
      default: 'Lorem ipsum title'
    },
    itemText: {
      type: String,
      default: 'Lorem ipsum text description'
    },
    itemType: {
      type: String,
      default: 'business energy'
    },
    linkingOption: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getPartner'])
  } 
}
</script>

<style lang="scss" scoped>

.why-compare-insurance-seo {
  .why-switch-insurance-with-bionic {
    &.item-container {
      text-align: left;
      padding-bottom: 40px;
      .item-image {
        margin-bottom: 25px;
        img {
          width: auto;
          height: 160px;
          margin-bottom: 2px;
        }
      }
      .item-title {
        width: 90%;
        margin-bottom: 16px;
      }
      .item-body {
        width: 90%;
      }
    }
  }
}

.how-bionics-gets-best-rates {
  .why-switch-insurance-with-bionic {
    &.item-container {
      padding-bottom: 40px;
      .item-image {
        margin-bottom: 71px;
        img {
          width: 160px;
        }
      }
      .item-title {
        width: 100%;
        margin-bottom: 20px;
      }
      .item-body {
        width: 100%;
      }
      .item-link-container {
        display: none;
      }
    }
  }
}

.why-switch-insurance-with-bionic {
  &.item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-bottom: 40px;
    .item-title {
      color: $main-title-color;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 20px;
      width: 237px;
      h4 {
        font-weight: bold;
      }
    }
    .item-image {
      margin-bottom: 25px;
      img {
        width: 160px;
        height: auto;
      }
    }
    .item-body {
      flex-grow: 1;
      color: $text-color;
      width: 270px;
      font-size: 16px;
      line-height: 22px;
    }
    .item-link-container {
      a {
        cursor: pointer;
        // color: $link-text-color;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        text-decoration: initial;
      }
      &:after {
        content: '';
        width: 12px;
        height: 17px;
        display: inline-block;
        position: relative;
        left: 8px;
        top: 3px;
      }
    }
  }
}
@media screen and (min-width: $desktop-breakpoint-min) {
  .why-compare-insurance-seo {
    .why-switch-insurance-with-bionic {
      &.item-container {
        padding-bottom: initial;
        text-align: center;
        .item-image {
          img {
            width: 220px;
            height: auto;
          }
        }
        .item-title {
          width: 260px;
        }
        .item-body {
          width: 270px;
        }
      }
    }
  }
  .how-bionics-gets-best-rates {
    .why-switch-insurance-with-bionic {
      &.item-container {
        padding-bottom: 0px;
        .item-title {
          width: 270px;
        }
        .item-body {
          width: 270px;
        }
      }
    }
  }

  .why-switch-insurance-with-bionic {
    &.item-container {
      padding-bottom: initial;
      .item-image {
        img {
          width: 220px;
          height: auto;
        }
      }
    }
  }
}
</style>
