<template>
  <div class="footer-block container-fluid">
    <div class="footer-top container-lg">
      <div class="row">
        <div class="wrapper col-lg-3 col-12">
          <span>Contact Us</span>
          <div class="wrapper__line">
            <LazyImage :src="require('@/assets/icons/phone.svg')" alt="phone icon" />
            <a href="tel:02033501595">0203 350 1595</a>
          </div>
          <div class="wrapper__line">
            <LazyImage :src="require('@/assets/icons/email.svg')" alt="email icon" />
            <a href="mailto:info@thinkbusinessloans.com">info@thinkbusinessloans.com</a>
          </div>
          <div class="wrapper__line align-items-start">
            <LazyImage :src="require('@/assets/icons/location-think.svg')" alt="location icon" />
            <span class="address">Ford House<br>31 - 34 Railway Street<br>Chelmsford<br>Essex<br>CM1 1QS</span>
          </div>
        </div>
        <div class="wrapper col-lg-3 col-12">
          <span>Sitemap</span>
          <a v-for="link of sitemap" :key="link.text" :href="link.url" class="wrapper__link">{{ link.text }}</a>
        </div>
        <div class="wrapper col-lg-3 col-12">
          <span>Find us on Social media</span>
          <div class="logos-wrapper">
            <a href="https://www.facebook.com/ThinkBusinessLoans/">
              <LazyImage :src="require('@/assets/images/facebook.svg')" alt="facebook logo" />
            </a>
            <a href="https://twitter.com/ThinkBizLoans">
              <LazyImage :src="require('@/assets/images/twitter.svg')" alt="twitter logo" />
            </a>

            <a href="https://www.linkedin.com/company/11220254/">
              <LazyImage :src="require('@/assets/images/linkedin.svg')" alt="linkedin logo" />
            </a>
          </div>
          <span class="title">Download our App</span>
          <div class="apps-wrapper">
            <a href="https://itunes.apple.com/gb/app/thinkapp/id1441797702">
              <LazyImage :src="require('@/assets/images/appstore.png')" alt="apple app store logo" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.httpsthinkbusinessloans.thinkappandroid&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <LazyImage :src="require('@/assets/images/google-play-badge-web.png')" alt="google play store logo" />
            </a>
          </div>
        </div>
        <div class="wrapper col-lg-3 col-12">
          <span>Reviews</span>
          <div class="trustpilot-widget-wrapper">
            <TrustPilot />
          </div>
        </div>
      </div>
      <div class="row">
        <p class="disclaimer">
          Think Business Loans is a trading name of Think Business Finance Limited, registered in England and Wales, company number 07115888. Registered office address: The Minster Building, 21 Mincing Lane, 4th Floor, London, England, EC3R 7AG. Think Business Finance Limited are authorised and regulated by the Financial Conduct Authority 724300 and operate in accordance with the Data Protection Act 1998, ICO registration number Z2226484.
        </p>
      </div>
      <div class="row">
        <p class="disclaimer">
          As a mortgage is secured against your home or other property, it could be repossessed if you do not keep up the mortgage repayments. Think carefully before securing other debts against your home. Think Business Finance Limited act as a Commercial Finance Credit Broker and not a Lender.
        </p>
      </div>
    </div>
    <div class="footer-bottom container-fluid">
      <div class="container-lg">
        <span>Copyright © 2020 Think Business Finance Limited. All rights reserved.</span>
        <span><LazyImage :src="require('@/assets/images/poweredby.png')" alt="" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import TrustPilot from '@/components/home/TrustPilot'
import LazyImage from '@/components/performance/LazyImage'
export default {
  name: 'FooterBlock',
  components: {
    TrustPilot,
    LazyImage
  },
  data () {
    return {
      sitemap: [
        {
          url: 'https://thinkbusinessloans.com/',
          text: 'Visit Think Business Loans'
        },
        {
          url: 'https://thinkbusinessloans.com/contact',
          text: 'Contact'
        },
        {
          url: 'https://thinkbusinessloans.com/cookie',
          text: 'Cookie Policy'
        },
        {
          url: 'https://thinkbusinessloans.com/privacy-policy/',
          
          text: 'Privacy Policy'
        },
        {
          url: 'https://thinkbusinessloans.com/terms',
          text: 'Terms & Conditions'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-block {
  font-size: 13px;
  padding: 0 !important;
  background: #e3e4e6;
}

.footer-bottom {
  background-color: white;
  padding: 0 !important;
  .container-lg {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
  }
}

.footer-top {
  // margin-top: 30px;
  margin-bottom: 20px;
  padding-top: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  > :first-child, .title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  span {
    text-align: start;
    font-size: 17px;
  }

  .address {
     font-size: 14px;
  }

  &__line {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > img {
      height: 20px;
      margin-right: 5px;
    }

    a {
     font-size: 14px;
    }
  }

  &__link {
    text-align: start;
    font-size: 14px;
  }

}

.logos-wrapper {
  display: flex;
  margin-bottom: 20px;

  img {
    margin-right: 20px;
    height: 40px;
  }
}

.apps-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    margin-bottom: 10px;
    height: fit-content;
    width: fit-content;
  }
}

.disclaimer {
  text-align: start;
  font-size: 13px;
}

@media (max-width: $mobile-breakpoint-max) {
  .disclaimer {
    margin-left: 15px;
  }

  .row {
    margin: auto;
  }

  .footer-bottom .container-lg {
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin: 10px;
    }
  }
}

@media (min-width: $desktop-breakpoint-min){
  .trustpilot-widget-wrapper{
    position: relative;
    left: -70px
  }
}
</style>
