export const BIONIC_CONTENT = {
  title: 'How Think business finance works',
  subTitle:
    'We only recommend what’s right for you. If you sign your business up for an unsuitable financing option, it could cost you thousands - our tech-enabled experts will help you make the right finance choices for your business.',
  item1Title: 'Quick eligibility check',
  item1Text:
    'Give us some basic information and we can tell you what you could borrow, without affecting your credit score.',
  item1Image: require('@/assets/images/phone-step-one.png'),
  item2Title: 'Talk to one of our finance experts',
  item2Text:
    'Our tech-enabled team will listen to your needs and discuss the different finance products available for you.',
  item2Image: require('@/assets/images/phone-step-two.png'),
  item3Title: 'Get your funds, fast',
  item3Text:
    'With your consent, we use smart data to find the information we need to process your loan.',
  item3Image: require('@/assets/images/phone-step-three.png')
}

export const RECOVERY_LOANS_CONTENT = {
  title: 'How we help you apply for the Recovery Loan Scheme (RLS)',
  subTitle:
    `We use our smart technology to find the best rates and terms. We'll help you choose from a trusted panel of lenders and guide you through the application process.`,
  item1Title: 'Quick eligibility check',
  item1Text:
    'With just some basic information online, we can tell you what you could borrow, without affecting your credit score.',
  item1Image: require('@/assets/images/phone-step-one.png'),
  item2Title: 'Talk to one of our finance experts',
  item2Text:
    'Our tech-enabled team will listen to your needs and discuss the different finance products available for you. This includes the Recovery Loan Scheme.',
  item2Image: require('@/assets/images/phone-step-two.png'),
  item3Title: 'Get your funds, fast',
  item3Text:
    'With your consent, we use smart data to apply for and process your loan.',
  item3Image: require('@/assets/images/phone-step-three.png')
}

export const ASSET_FINANCE_CONTENT = {
  title: 'How we help you apply for Asset Finance',
  subTitle:
    `We use our smart technology to find the best rates and terms. We'll help you choose from a trusted panel of lenders and guide you through the application process.`,
  item1Title: 'Quick eligibility check',
  item1Text:
    'With just some basic information online, we can tell you what you could borrow, without affecting your credit score.',
  item1Image: require('@/assets/images/phone-step-one.png'),
  item2Title: 'Talk to one of our finance experts',
  item2Text:
    'Our tech-enabled team will listen to your needs and discuss the different finance products available for you.',
  item2Image: require('@/assets/images/phone-step-two.png'),
  item3Title: 'Get your funds, fast',
  item3Text:
    'With your consent, we use smart data to apply for and process your loan.',
  item3Image: require('@/assets/images/phone-step-three.png')
}
