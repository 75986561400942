<template>
  <div class="page-info-section" :class="cssClass">
    <div class="container-fluid">
      <div class="container">
        <div class="justify-content-lg-center row">
          <div lg="12" class="col-lg-12">
            <h3 class="page-info-section__title">
              <slot name="title">
                <span :class="textColor">
                  {{ title }}
                </span>
              </slot>
            </h3>
          </div>
        </div>
        <div class="justify-content-lg-center row">
          <div class="page-info-section__text col-lg-10">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">

export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    cssClass: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
.page-info-section {
  background-color: $background-light-grey;
  padding-top: 64px;
  padding-bottom: 64px;
  overflow: hidden;
  @media screen and (max-width: $mobile-breakpoint-max) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  &__title {
    color: $main-title-color;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    margin-bottom: 36px;
    @media screen and (max-width: $mobile-breakpoint-max) {
      font-size: 26px;
      line-height: 32px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  &__text {
    color: $text-color;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;

    @media screen and (max-width: $mobile-breakpoint-max) {
      font-size: 16px;
      text-align: left;
    }
  }
}
</style>
