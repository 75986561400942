/**
 * Returns string with last two words wrapped in span 
 * class name used to prevent one word on a line
 *
 * @param {String} val string passed in
 * @return {String} last two words wrapped in span tag
 */

export default function (val) {
  if (!val) return ''
  if (val.split(' ').length === 1) return val
  let stringArr = val.split(' ')
  stringArr.push('</span>')
  stringArr.splice(stringArr.length - 3, 0, '<span class="ws">')
  return stringArr.join(' ')
}
