<template>
  <PageInfoSection 
    :title="title" 
    :css-class="`list-of ${cssClass}`"
    :text-color="textColor"
  >
    <p class="list-of__subtitle">
      {{ subtitle }}
    </p>
    <ListOfItem
      v-for="(item, i) in items"
      :key="i"
      :img-src="item.image"
      :item-title="item.title"
      :item-text="item.text"
      :show-image="showImage"
    />
  </PageInfoSection>
</template>

<script>
import PageInfoSection from '@/components/home/PageInfoSection.vue'
import ListOfItem from '@/components/home/ListOfItem.vue'

export default {
  components: {
    PageInfoSection,
    ListOfItem
  },
  props: {
    cssClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    showImage: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.list-of {
  &__subtitle {
    
    @media(max-width: $mobile-breakpoint-max) {
      text-align: left;
    }

  }
}
  
</style>

<style lang="scss">
.list-of {
  &.page-info-section {
    .page-info-section__text {
      text-align: center;
    }
  }
}
</style>
