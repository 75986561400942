<template>
  <div class="input-cta">
    <div class="input-cta__section">
      <h3 class="page-section__title input-cta__title">
        {{ content.title }}
      </h3>
      <p class="page-section__text">
        {{ content.info }}
      </p>
      <LoanInputHome :focus-form="false" />
    </div>
    <div class="input-cta__section">
      <img
        class="input-cta__section__img"
        src="@/assets/images/lady-on-phone.png"
        alt="lady on phone"
      >
    </div>
  </div>
</template>

<script>
import LoanInputHome from '@/components/home/LoanInputHome'
export default {
  name: 'InputCTA',
  components: {
    LoanInputHome
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-cta {
  display: flex;
  flex-direction: column-reverse;

  &__title {
    max-width: 420px;
  }

  @media (min-width: $mobile-breakpoint-max) {
    flex-direction: row;
  }

  &__section {
    &__img {
      width: 220px;
      margin-bottom: 16px;

      @media (min-width: $mobile-breakpoint-max) {
        width: auto;
        margin-bottom: 0;
      }
    }

    @media (min-width: $mobile-breakpoint-max) {
      width: 50%;
    }
  }
}
.page-section__title,
.page-section__text {
    @media (min-width: $mobile-breakpoint-max) {
        text-align: left;
    }
  margin: 0;
}

.page-section__text {
  margin-top: 13px;
  margin-bottom: 31px;
}
</style>
<style lang="scss">
.input-cta {
  .amount-form {
    padding: 0;

    &__title {
      text-align: center;
       @media (min-width: $mobile-breakpoint-max) {
        text-align: left;
       }
    }

    &__form {
      margin-left: auto;
      margin-right: auto;
      @media (min-width: $mobile-breakpoint-max) {
        margin: 0;
      }
    }
  }
}
</style>
