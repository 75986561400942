<template>
  <div
    class="public-liability-insurance-cover-item-container justify-content-lg-center row"
  >
    <div
      v-if="showImage"
      class="item-image col-md-12 col-lg-3"
    >
      <LazyImage :src="imgSrc" alt="" />
    </div>
    <div class="item-body col-md-12" :class="`col-lg-${ifImage}`">
      <div class="justify-content-lg-center row">
        <div class="item-title col-12">
          <h4 :class="[getPartner ? `${getPartner.className}__color` : '']">
            {{ itemTitle }}
          </h4>
        </div>
        <div class="item-text col-12">
          <div v-html="itemText" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import { mapGetters } from 'vuex'
import LazyImage from '@/components/performance/LazyImage'
export default {
  components: {
    LazyImage
  },
  props: {
    imgSrc: {
      type: String,
      default: 'Lorem ipsum image source'
    },
    itemTitle: {
      type: String,
      default: 'Lorem ipsum title'
    },
    itemText: {
      type: String,
      default: 'Lorem ipsum text'
    },
    showImage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getPartner']),
    ifImage () {
      if (this.showImage) return '9'
      return '12'
    }
  }
}
</script>

<style lang="scss">
.public-liability-insurance-cover-item-container {
  align-items: center;
  margin: 40px 0;
  .item-image {
    margin-bottom: 20px;

    img {
      width: 50%;
      height: auto;
    }
  }

  .item-body {
    .item-title {
      h4 {
        color: $text-color-orange;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        margin-bottom: 20px;
        text-align: left;
      }
    }

    .item-text {
      p {
        color: $text-color;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: $desktop-breakpoint-min) {
  .public-liability-insurance-cover-item-container {
    margin-top: 0px;
    &:not(:last-child) {
      margin-top: 60px;
      margin-bottom: 30px;
    }
    .item-image {
      margin-bottom: initial;
      height: 120px;
      width: 166px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        // width: 100%;
        width: fit-content
      }
    }
    .item-body {
      .item-title {
        h4 {
          text-align: left;
        }
      }
      .item-text {
        p {
          text-align: left;
        }
      }
    }
  }
}
</style>
