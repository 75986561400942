<template>
  <div class="page-section" :class="cssClass">
    <div class="container-fluid">
      <div class="container">
        <div class="justify-content-lg-center row">
          <div :class="`col-lg-${titleCols}`">
            <h3 class="page-section__title" :class="titleCss">
              <span :class="textColor">
                {{ title }}
              </span>
            </h3>
          </div>
          <div :class="[subTitleContainerCss, `col-lg-${subTitleCols}`]">
            <p class="page-section__text" :class="subTitleCss">
              {{ text }}
            </p>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    text: {
      type: String,
      default: 'Lorem ipsum text description'
    },
    subTitleContainerCss: {
      type: String,
      default: ''
    },
    cssClass: {
      type: String,
      default: ''
    },
    titleCss: {
      type: String,
      default: ''
    },
    subTitleCss: {
      type: String,
      default: ''
    },
    subTitleCols: {
      type: String,
      default: '6'
    },
    titleCols: {
      type: String,
      default: '11'
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
.page-section {
  padding-top: 99pt;
  padding-bottom: 100pt;
  overflow: hidden;
  background-color: white;
  &__title {
    color: $main-title-color;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
  }

  &__text {
    color: $text-color;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }

  @media (max-width: $mobile-breakpoint-max) {
    padding-top: 22px !important;

    &__title {
      font-size: 26px !important;
      letter-spacing: 0px !important;
      line-height: 32px !important;
      text-align: left !important;
    }

    &__text {
      color: $text-color;
      font-size: 16px !important;
      line-height: 22px !important;
      text-align: left !important;
    }
  }
}
.no-border-top {
  border-top: none;
}
.page-section.blue-theme {
  background-color: $main-title-color;

  .page-section__title {
    color: white;
  }

  .page-section__text {
    color: white;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }

  .description {
    color: white;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    margin-top: 37px;
    @media (max-width: $mobile-breakpoint-max) {
      margin-top: 23px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: $mobile-breakpoint-max) {
    padding-top: 69px;
    .page-section__title {
      font-size: 32px;
      letter-spacing: -0.3px;
      line-height: 34px;
    }
    .page-section__text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }
}

.page-section.white-theme {
  background-color: $background-light-grey;

  .page-section__title {
    color: $main-title-color;
  }

  .page-section__text {
    color: $text-color;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }

  .description {
    color: $main-title-color;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-top: 40px;
    @media (max-width: $mobile-breakpoint-max) {
      margin-top: 25px;
      margin-bottom: 45px;
    }
  }

  @media (max-width: $mobile-breakpoint-max) {
    padding-top: 69px;
    .page-section__title {
      font-size: 32px;
      letter-spacing: -0.3px;
      line-height: 34px;
    }
    .page-section__text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }
  .last {
    margin-bottom: 80px;
  }
}
</style>
