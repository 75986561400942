<template>
  <div
    class="navigation-container nav-non-partner d-none d-lg-flex justify-content-between align-items-center container-fluid"
  >
    <div>
      <router-link
        to="/"
      >
        <img
          src="@/assets/images/logos/think-logo.svg"
          alt="Bionic"
          class="logo"
        >
      </router-link>
    </div>

    <div 
      class="d-flex justify-content-between align-items-center"
      :class="[menuItems.length > 0 ? 'nav-menu' : '']"
    >
      <a
        v-for="item in menuItems"
        :key="item.text"
        class="menu-item"
        :href="item.url"
      >
        {{ item.text }}
      </a>
      <div class="d-flex justify-content-between align-items-center position-relative">
        <img
          src="@/assets/icons/phone-think.png"
          alt="Bionic"
          class="phone"
        >
        <a class="menu-phone-text" :href="`tel:${phone}`">
          {{ friendlyPhone }}
        </a>
        <OpeningHoursMessage class="opening-hours-message-outer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OpeningHoursMessage from '@/components/shared/OpeningHoursMessage'
export default {
  name: 'Navigation',
  components: {
    OpeningHoursMessage
  },
  props: {
    customPhoneNo: {
      type: String,
      default: ''
    },
    isHome: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['getPhone', 'getFriendlyPhone']),
    phone () {
      return this.customPhoneNo.replace(/ /g, '') || this.getPhone
    },
    friendlyPhone () {
      return this.customPhoneNo || this.getFriendlyPhone
    }
  }
}
</script>

<style lang="scss">
.nav-non-partner {
  .opening-hours-message-outer {
    position: absolute;
    bottom: -34px;
    left: 11px;
    width: 100%;
    font-size: 11px;
}
}
</style>

<style lang="scss" scoped>
.navigation-container {
  max-height: 87px;
  padding: 0px !important;
  position: relative;
  z-index: 2010;
}

.seo-product-hero .navigation-container {
  box-shadow: 0 1px 1px 0 #f2f1f2;
}

img.logo {
  margin: 21px 0 25px 0;
  height: 41px;
}

img.phone {
  height: 20px;
  margin-right: 10px;
}

.nav-menu {
  flex-basis: 60%;
}

.menu-item{
  color: $main-title-color;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.menu-phone-text {
  color: $main-title-color;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
</style>
