<template>
  <div class="page-section padding-top-bottom-64">
    <div class="container-fluid">
      <div class="container">
        <div class="justify-content-lg-center row">
          <div :class="['col-md-12', `col-lg-${titleCols}`]">
            <h2 
              :class="textColor"
              class="page-section__title"
            >
              {{ title }}
            </h2>
          </div>
          <div :class="['col-md-12', `col-lg-${subTitleCols}`]">
            <p class="page-section__text">
              <slot />
            </p>
          </div>
        </div>
        <div class="content-row justify-content-lg-center row">
          <div class="col-lg-3">
            <HowBionicProductWorksItem
              :img-src="item1Image"
              item-type="bionic-product-step-one"
              item-number="01"
              :item-title="item1Title"
              :item-text="item1Text"
              page-type="energy"
            />
          </div>
          <div class="col-lg-6">
            <HowBionicProductWorksItem
              :img-src="item2Image"
              item-type="bionic-product-step-two"
              item-number="02"
              :item-title="item2Title"
              :item-text="item2Text"
              page-type="energy"
            />
          </div>
          <div class="col-lg-3">
            <HowBionicProductWorksItem
              :img-src="item3Image"
              item-type="bionic-product-step-three"
              item-number="03"
              :item-title="item3Title"
              :item-text="item3Text"
              page-type="energy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HowBionicProductWorksItem from '@/components/home/HowBionicProductWorksItem.vue'

export default {
  components: {
    HowBionicProductWorksItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    subTitle2: {
      type: String,
      default: ''
    },
    subTitle3: {
      type: String,
      default: ''
    },
    item1Title: {
      type: String,
      default: ''
    },
    item1Text: {
      type: String,
      default: ''
    },
    item2Title: {
      type: String,
      default: ''
    },
    item2Text: {
      type: String,
      default: ''
    },
    item3Title: {
      type: String,
      default: ''
    },
    item3Text: {
      type: String,
      default: ''
    },
    item1Image: {
      type: String,
      default: ''
    },
    item2Image: {
      type: String,
      default: ''
    },
    item3Image: {
      type: String,
      default: ''
    },
    subTitleCols: {
      type: String,
      default: '8'
    },
    titleCols: {
      type: String,
      default: '10'
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.content-row {
  margin-top: 50px;
}

.padding-top-bottom-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.page-section {
  background-color: white;

  &__title {
    color: $main-title-color;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    margin-bottom: 16px;

    &--dark {
      color: $black;
    }
  }

  &__text {
  color: $text-color;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  }
}

@media (max-width: $mobile-breakpoint-max) {
  .content-row {
    margin-top: 0;
  }
}
</style>
