<template>
  <div class="page">
    <HomepageHero 
      v-bind="heroContent" 
      :menu-items="menuItems"
      :text-color="textColour"
    />
    <SEOLogos v-bind="seoLogos" />
    <HowBionicWorks
      :text-color="textColour"
      :title="howBionicWorks.title"
      :sub-title="howBionicWorks.subTitle"
      :item1-title="howBionicWorks.item1Title"
      :item1-text="howBionicWorks.item1Text"
      :item1-image="howBionicWorks.item1Image"
      :item2-title="howBionicWorks.item2Title"
      :item2-text="howBionicWorks.item2Text"
      :item2-image="howBionicWorks.item2Image"
      :item3-title="howBionicWorks.item3Title"
      :item3-text="howBionicWorks.item3Text"
      :item3-image="howBionicWorks.item3Image"
      title-cols="12"
      sub-title-cols="10"
    >
      {{ howBionicWorks.subTitle }}
    </HowBionicWorks>
    <template v-if="isRecoveryLoans || isAssetFinance">
      <ListOf
        :title="listOfContent.title"
        :subtitle="listOfContent.subtitle"
        :items="listOfContent.items"
        :show-image="false"
      />
      <div class="page-section padding-top-bottom-64">
        <div class="container">
          <InputCTA :content="inputCTAContent" />
        </div>
      </div>
    </template>
    <WaysBionicFinanceCanHelp :text-color="textColour" />
    <ListOf
      :title="typesOfBusinessFinance.title"
      :subtitle="typesOfBusinessFinance.subtitle"
      :items="typesOfBusinessFinance.items"
      :text-color="textColour" 
    />
    <FooterBlock />
  </div>
</template>

<script>
import selectedLpKeywords from '@/keywords/selectedLpKeywords'
import HomepageHero from '@/components/home/HomepageHero'
import SEOLogos from '@/components/home/SEOLogos'
import HowBionicWorks from '@/components/home/HowBionicWorks'
import WaysBionicFinanceCanHelp from '@/components/home/WaysBionicFinanceCanHelp'
import ListOf from '@/components/home/ListOf'
import FooterBlock from '@/components/home/FooterBlock'
import IsInViewport from '@mic/frontend/mixins/IsInViewport'
import { RECOVERYLOANS, ASSETFINANCE } from '@/helpers/constants/partner-names'
import { HERO_CONTENT_RECOVERY_LOANS, HERO_CONTENT_ASSET_FINANCE } from '@/content/hero-content'
import { LIST_OF_BIONIC, LIST_OF_RECOVERY_LOANS, LIST_OF_ASSET_FINANCE } from '@/content/list-of'
import { INPUT_CTA_RECOVERY_LOANS, INPUT_CTA_ASSET_FINANCE } from '@/content/input-cta-content'
import InputCTA from '@/components/home/InputCTA'
import { BIONIC_CONTENT, RECOVERY_LOANS_CONTENT, ASSET_FINANCE_CONTENT } from '@/content/how-bionic-works'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DefaultHome',
  metaInfo () {
    return {
      title: `${this.lpKeywords.title} | Think Business Loans`
    }
  },
  components: {
    SEOLogos,
    HomepageHero,
    InputCTA,
    ListOf,
    WaysBionicFinanceCanHelp,
    HowBionicWorks,
    FooterBlock
  },

  mixins: [IsInViewport],

  data () {
    return {
      pageName: 'business-finance',
      ASSETFINANCE,
      RECOVERYLOANS,
      recoveryLoansInfo: LIST_OF_RECOVERY_LOANS,
      lpKeywords: {},
      menuItems: [
        {
          text: 'About Us',
          url: 'https://thinkbusinessloans.com/about'
        },
        {
          text: 'Knowledge Hub',
          url: 'https://thinkbusinessloans.com/knowledge'
        },
        {
          text: 'News',
          url: 'https://thinkbusinessloans.com/news'
        },
        {
          text: 'Partners',
          url: 'https://thinkbusinessloans.com/partners'
        }
      ],
      header: {
        title: 'We’ll help you get your business finances sorted'
      },
      seoLogos: {
        logos: [
          require('@/assets/images/lloyds-bank.svg'),
          require('@/assets/images/barclays_logo.svg'),
          require('@/assets/images/santander-logo.svg'),
          require('@/assets/images/atom-bank.svg'),
          require('@/assets/images/aldermore-logo.svg'),
          require('@/assets/images/iwoca-logo.svg')
        ]
      },
      typesOfBusinessFinance: LIST_OF_BIONIC
    }
  },
  computed: {
    ...mapGetters(['getPartner']),
    isRecoveryLoans () {
      if (this.getPartner && this.getPartner.name === this.RECOVERYLOANS) {
        return true
      }
      return false
    },
    isAssetFinance () {
      if (this.getPartner && this.getPartner.name === this.ASSETFINANCE) {
        return true
      }
      return false
    },
    heroContent () {
      if (this.isRecoveryLoans) {
        return HERO_CONTENT_RECOVERY_LOANS
      } else if (this.isAssetFinance) {
        return HERO_CONTENT_ASSET_FINANCE
      } else {
        return {
          title: this.lpKeywords.headline,
          sublines: this.lpKeywords.subLine
        }
      }
    },
    howBionicWorks () {
      if (this.isRecoveryLoans) {
        return RECOVERY_LOANS_CONTENT
      } else if (this.isAssetFinance) {
        return ASSET_FINANCE_CONTENT
      } else {
        return BIONIC_CONTENT
      }
    },
    listOfContent () {
      if (this.isRecoveryLoans) {
        return LIST_OF_RECOVERY_LOANS
      } else {
        return LIST_OF_ASSET_FINANCE
      } 
    },
    textColour () {
      if (!this.getPartner) return 'navy-color'
      if (this.getPartner.name === this.RECOVERYLOANS || this.getPartner.name === this.ASSETFINANCE) return 'navy-color'
      return 'color-black'
    },
    inputCTAContent () {
      if (this.isRecoveryLoans) {
        return INPUT_CTA_RECOVERY_LOANS
      } else {
        return INPUT_CTA_ASSET_FINANCE
      } 
    }
  },
  mounted () {
    this.setTextColour(this.textColour)
    this.lpKeywords = selectedLpKeywords()
  },
  methods: {
    ...mapActions(['setTextColour'])
  }
}
</script>

<style lang="scss" scoped>
.h-line {
  height: 4px;
}

.page {
  background-color: #f8f8f8;
}
</style>
