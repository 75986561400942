<template>
  <div>
    <div
      class="navigation-container-mobile d-flex d-lg-none justify-content-between container-fluid"
    >
      <div class="mobile-logo">
        <router-link
          to="/"
        >
          <img
            src="@/assets/images/logos/think-logo.svg"
            alt="Bionic"
            class="logo"
          >
        </router-link>
      </div>
      <div
        class="d-flex align-items-center icons-container justify-content-between"
        :class="visibilityClass"
      >
        <a :href="`tel:${getPhone}`"><img src="@/assets/icons/call-circle-orange.svg" alt="oragne phone icon"></a>
        <button class="btn-hamburger" @click="toggleModal">
          <img src="@/assets/icons/burger-menu.svg" alt="hamburger menu icon">
        </button>
      </div>
    </div>
    <div v-if="isModalOpen" class="modal-dialog">
      <div class="modal-content">
        <a
          v-for="item in menuItems"
          :key="item.text"
          class="menu-item"
          :href="item.url"
        >
          {{ item.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    customPhoneNo: {
      type: String,
      default: ''
    },
    isModalOpen: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['getPhone']),
    visibilityClass () {
      return this.menuItems.length > 0
        ? 'visible'
        : 'invisible'
    },
    phone () {
      return this.getPhone
    }
  },

  methods: {
    toggleModal () {
      const toggle = this.isModalOpen = !this.isModalOpen
      return toggle
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-container-mobile {
  height: 69px;
  align-items: center;

  .mobile-logo {
    img {
      height: 35px;
    }
  }

  .icons-container {
    min-width: 80px;

    a img {
      height: 28px;
    }
  }
}

.btn-hamburger {
   border: none;
   background-color: #F6F7FA;

   img {
     margin-top: -6px;
   }
}

.modal-dialog {
  margin: 0;
  position: absolute;
  left: 0;
  z-index: 50;
  min-width: 100%;
  outline: 0;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    padding-top: 20px
  }

  .menu-item{
    color: $main-title-color;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
</style>
