<template>
  <PageSection
    :title="WaysBionicFinanceCanHelp.title"
    :text="WaysBionicFinanceCanHelp.text"
    :text-color="textColor"
    css-class="why-compare-insurance-seo padding-top-bottom-64"
    title-cols="10"
    sub-title-cols="9"
  >
    <div class="content-row row">
      <div
        v-for="(item, index) in WaysBionicFinanceCanHelpItems"
        :key="index"
        :class="index === 1 ? `col-lg-6` : `col-lg-3`"
      >
        <ReasonToChooseBionicItem
          :img-src="item.imgName"
          :item-type="item.itemType"
          :item-title="item.itemTitle"
          :item-text="item.itemText"
          :linking-option="item.linkingOption"
        />
      </div>
    </div>
  </PageSection>
</template>

<script>
import PageSection from '@/components/home/PageSection.vue'
import ReasonToChooseBionicItem from '@/components/home/ReasonToChooseBionicItem.vue'
export default {
  components: {
    PageSection,
    ReasonToChooseBionicItem
  },
  props: {
    itemLinkingOption: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      WaysBionicFinanceCanHelp: {
        title: 'How Think finance can help your business',
        text:
          'Our finance options can help businesses in more ways than one, including those that are struggling during the current crisis.' },
      WaysBionicFinanceCanHelpItems: [
        {
          imgName: require('@/assets/images/insurance-seo-customised.png'),
          itemType: 'exclusive-deals',
          itemTitle: 'Meet the wage bill',
          itemText:
            'With businesses severely impacted by the need to stay at home, paying staff in the short term can be tricky. Our financing options could help with wage issues.',
          linkingOption: this.itemLinkingOption
        },
        {
          imgName: require('@/assets/images/insurance-seo-guarantee.png'),
          itemType: 'lifetime-guarantee',
          itemTitle: 'Cover shortfalls',
          itemText:
            'Is it taking longer to get invoices paid? We can find the funds in the interim, so you can keep running your business.',
          linkingOption: this.itemLinkingOption
        },
        {
          imgName: require('@/assets/images/insurance-seo-renewals.png'),
          itemType: 'renewals',
          itemTitle: 'Grow the business',
          itemText:
            'There are always opportunities to be had and we can provide the finance your business needs to keep growing',
          linkingOption: this.itemLinkingOption
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.content-row {
  margin-top: 40px;
}
.padding-top-bottom-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
</style>
