<template>
  <div class="container-fluid">
    <div class="seo-logos container">
      <p class="seo-logos__text seo-logos__text--sm">
        We compare a panel of over 100 trusted lenders
      </p>
      <div class="row">
        <div class="seo-logos__trustpilot trustpilot-row col-sm-3 col-md-3 col-lg-2">
          <!-- <TrustPilot class="trustpilot-widget" /> -->
          <div class="trustpilot-widget-wrapper">
            <TrustPilot />
          </div>
          <div class="seo-logos__line" />
        </div>
        <div class="seo-logos__logos align-self-center col-sm-9 col-md-9 col-lg-10">
          <div class="seo-logos-container d-inline-block">
            <p class="seo-logos__text seo-logos__text--lg">
              We compare a panel of over 100 trusted lenders
            </p>
            <div class="seo-logos-row">
              <!-- We need two sets of the logos to create in the 'infinite' effect -->
              <img
                v-for="(logo, i) in logos"
                :key="`${i}-mobile-row-1`"
                class="logo"
                :src="logo"
                alt="logo"
              >
              <img
                v-for="(logo, i) in logos"
                :key="`${i}-mobile-row-2`"
                class="logo"
                :src="logo"
                alt="logo"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TrustPilot from '@/components/home/TrustPilot.vue'
export default {
  components: {
    TrustPilot
  },
  props: {
    logos: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: white;
}

.seo-logos {
    padding-top: 12px;
    padding-bottom: 14px;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 55px;
      width: 100%;
    }
  }

  &__line {
    height: 45px;
    width: 1px;
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    top: 8px; 
    right: 10%;  

    @media(min-width: 768px) {
      height: 78px;
      top: 19px;
      right: 5%;
    }

     @media(min-width: 1101px) {
      right: 0;
    }

    @media(min-width: 1200px) {
      right: 5%;
    }
  }

  &__text {
    color: #152D72;

    &--sm {
      font-size: 12px;
      @media(min-width: 768px) {
        display: none;
      }
    }

    &--lg {
      display: none;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 3px;

      @media(min-width: 768px) {
        display: block;
      }
    }
  }
}

.seo-logos-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 48px;
    z-index: 2;
    top: 0;
    // background-image: linear-gradient(to right, #fff, rgba(#fff, 0));
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 48px;
    z-index: 2;
    top: 0;
    // background-image: linear-gradient(to left, #fff, rgba(#fff, 0));
  }
}
.seo-logos-row {
  display: inline-block;
  white-space: nowrap;
  animation: 20s logoscroll infinite linear;
}

.seo-logos {
  .logo {
    max-height: 45px;
    display: inline;
    vertical-align: middle;
    margin-left: 28px;
    max-width: 120px;
  }

  &__trustpilot {
    width: 32%;
  }

  &__logos {
    width: 68%;
  }
}

.trustpilot-widget-wrapper {
  position: relative;

  @media(min-width: 768px) {
    top: 19px;
  }
}

@media (max-width: $desktop-breakpoint-min) {
  .seo-logos {
    margin-top: 10px;
  }

  .trustpilot-widget-wrapper{
    left: 0;
  }
}

@keyframes logoscroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@media (max-width: 340px) {
  .trustpilot-widget-wrapper{
    left: -20px;
  }
}

@media (max-width: 1100px) and (min-width: 900px) {
  .trustpilot-row {
    padding: 0;
  }
}
</style>

<style lang="scss">
.seo-logos {
  .trustpilot-widget-wrapper iframe {
      height: 59px !important;
      width: 99px !important;

    @media(min-width: 768px) {
      height: 74px !important;
      width: 145px !important;
    }
  }
}
</style>
