export const LIST_OF_BIONIC = {
  title: 'Different types of business finance available?',
  subtitle:
      'There’s not just one kind of business loans but several types depending on your needs. It’s important to choose the best for your unique business circumstances.',
  items: [
    {
      image: require('@/assets/images/insurance-public-liability.png'),
      title: 'Asset finance',
      text: '<p>Asset Finance solutions allow your business to free up much needed capital to help accelerate business growth. We offer competitive funding packages, Hire Purchase, Asset Purchase or Refinance, and everything in between.</p>'
    },
    {
      image: require('@/assets/images/finance-employee.png'),
      title: 'Bridging loans',
      text: '<p>A Bridging Loan is a short term funding option, mainly used on development and property projects, but can also be utilised for business loan purposes. Due to it being short term, interest is quoted on a monthly rate.</p>'
    },
    {
      image: require('@/assets/images/finance-professional.png'),
      title: 'Commercial mortgages',
      text: '<p>With the largest variety of high street, boutique and independent commercial mortgage providers and property lenders. Our technology and experience allows us to secure the very best commercial mortgage and refinancing rates, secured by property across a wide range of business areas.</p>'
    },
    {
      image: require('@/assets/images/finance-commercial-building.png'),
      title: 'Unsecured business loans',
      text: '<p>Bionic Business Loans are innovators in Unsecured business loans for all business types and sizes. We pride ourselves in finding our customers the best rates and terms, tailored specifically to their requirements. Access to the largest unsecured lending panel, our buying power allows our clients to receive exclusive rates and terms not available direct or via other comparison services.</p>'
    },
    {
      image: require('@/assets/images/finance-stock-and-content.png'),
      title: 'Working capital',
      text: '<p>With our highly competitive working capital solutions, decisions made within days, and funds in your account within 2 hours; react quickly to any investment or business opportunity.</p>'
    }
  ]
}

export const LIST_OF_RECOVERY_LOANS = {
  title: 'What is the Recovery Loan Scheme (RLS)?',
  subtitle:
      'The Recovery Loan Scheme (RLS) was launched on 6th April 2021 to help UK businesses access finance as they recover following the pandemic. The scheme can be used for business purposes, such as growth, cash flow and investment.',
  items: [
    {
      title: 'Who is eligible',
      text: '<p>The scheme  aims to help any UK trading business disrupted by the coronavirus pandemic. If your business has already borrowed from any other government COVID-19 financial support you will still have access to the Recovery Loan Scheme.</p>'
    },
    {
      title: 'How it works',
      text: '<p>The government provides the lender a guarantee of 80% of the loan. Lenders can provide up to £10 million per business but the actual amount that is offered and the terms are at their discretion. It is important to note that a lender can offer you an alternative loan to the Recovery Loan Scheme with better terms. <p> hello</p></p>'
    },
    {
      title: 'How to apply',
      text: '<p>We make it easy for you. Our experts will compare options across government accredited lenders and get you a fast decision. If you choose to proceed, we’ll help you put your application together and ensure you receive your funds without any delay.</p>'
    }
  ]
}
export const LIST_OF_ASSET_FINANCE = {
  title: 'What is Asset Finance?',
  subtitle:
      'Asset Finance is a form of lending that allows you to secure business assets without having to pay the total amount upfront. It can also be used as an alternative to cash flow funding, enabling you to use your assets as collateral to secure a loan.',
  items: [
    {
      title: 'How it works',
      text: `<p>Commonly there are two different ways to use Asset Finance.</p>
            <p><b>Asset Finance</b> allows you to purchase or lease an asset and spread the cost over a set amount of time. It can be used on any type of asset, whether it’s furniture, machinery or even buildings and warehouses.</p>
            <p><b>Asset Refinance</b> gives you the option to release cash into your business using your existing assets. It can be used to consolidate debt or provide security when structuring a deal. Depending on your situation, lenders will typically lend up to 80% of your asset's value.</p>
            <p>Depending on which way you go, whether its new equipment or a new loan, the financing will work in different ways. General asset based products include Equipment leasing, Hire purchase, Finance leases, Operating leases and Asset refinance.</p>`
    },
    {
      title: 'How to apply',
      text: '<p>We make it easy for you. Our experts will talk through the right products for your requirements, compare options across our panel of over 100 lenders and get you a fast decision. If you choose to proceed, we’ll help you put your application together and ensure you receive your finance without any delay.</p>'
    }
  ]
}
