export const lpKeywords = [
  {
    parameter: null,
    keyword: 'Find a loan',
    title: 'Find a loan',
    headline: `We’ll help you get your business finances sorted`,
    subLine: [
      'Our experts <b>compare multiple products across 100s of lenders</b>',
      'We have <b>preferential relationships</b> with banks and alternative lenders',
      'Expert help to find the option that’s right for you'
    ],
    proudct: ''
  },
  {
    parameter: 'recovery',
    keyword: 'Recovery loans',
    title: 'Recovery loans',
    headline: 'Compare recovery loans',
    subLine: [
      'Free business recovery loan comparison with no credit check',
      'Exclusive recovery loan deals with banks and alternative lenders',
      'Speak to a recovery loans specialist to help you every step of the way'
    ],
    proudct: 'reocovery loans'
  },
  {
    parameter: 'business-loans',
    keyword: 'Business loans',
    title: 'Business loans',
    headline: 'Compare business loans',
    subLine: [
      'Free business loans comparison with no credit check',
      'Exclusive business loan deals with banks and alternative lenders',
      'Speak to a loans specialist to help you every step of the way'
    ],
    proudct: 'Business loans'
  },
  {
    parameter: 'business-loan',
    keyword: 'Business loan',
    title: 'Business loan',
    headline: 'Get a business loan, hassle-free',
    subLine: [
      'Free business loans comparison with no credit check',
      'Exclusive business loan deals with banks and alternative lenders',
      'Speak to a loans specialist to help you every step of the way'
    ],
    proudct: 'Business loan'
  },
  {
    parameter: 'business-loan-uk',
    keyword: 'Business loans UK',
    title: 'Business loans UK',
    headline: 'Compare UK Business loans',
    subLine: [
      'Free business loans comparison',
      'Exclusive business loan deals with banks and alternative lenders',
      'Speak to a loans specialist to help you every step of the way'
    ],
    proudct: 'Business loans UK'
  },
  {
    parameter: 'business-recovery-loan',
    keyword: 'Compare business recovery loanss',
    title: 'Compare business recovery loanss',
    headline: 'Compare business recovery loans',
    subLine: [
      'Free business recovery loan comparison',
      'Exclusive recovery loan deals with banks and alternative lenders',
      'Speak to a recovery loans specialist to help you every step of the way'
    ],
    proudct: 'Recovery Loans'
  },
  {
    parameter: 'business-lending',
    keyword: 'Business lending',
    title: 'Business lending',
    headline: 'Compare business lending options',
    subLine: [
      'Free business lending comparison',
      'Exclusive business lending deals with banks and alternative lenders',
      'Speak to a lending specialist to help you every step of the way'
    ],
    proudct: 'business-lending'
  },
  {
    parameter: 'business-finance-loans',
    keyword: 'Business finance loans',
    title: 'Business finance loans',
    headline: 'Compare business finance loans',
    subLine: [
      'Free business loans comparison',
      'Exclusive business finance loans with banks and alternative lenders',
      'Speak to a lending specialist to help you every step of the way'
    ],
    proudct: 'Business finance loans'
  },
  {
    parameter: 'business-funding',
    keyword: 'Business funding',
    title: 'Business funding',
    headline: 'Compare business funding options',
    subLine: [
      'Free business funding comparison',
      'Exclusive business funding deals with banks and alternative lenders',
      'Speak to a funding specialist to help you every step of the way'
    ],
    proudct: 'Business funding'
  },
  {
    parameter: 'unsecured-business-loans',
    keyword: 'Unsecured business loans',
    title: 'Unsecured business loans',
    headline: 'Compare unsecured business loans',
    subLine: [
      'Unsecured business loan comparison',
      'Exclusive unsecured loan deals with banks and alternative lenders',
      'Speak to a funding specialist to help you every step of the way'
    ],
    proudct: 'Unsecured business loans'
  },
  {
    parameter: 'secured-business-loans',
    keyword: 'Secured business loans',
    title: 'Secured business loans',
    headline: 'Compare secured business loans',
    subLine: [
      'Secured business loan comparison',
      'Exclusive secured loan deals with banks and alternative lenders',
      'Speak to a funding specialist to help you every step of the way'
    ],
    proudct: 'Secured business loans'
  },
  {
    parameter: 'business-finance',
    keyword: 'Business finance',
    title: 'Business finance',
    headline: 'Compare business finance deals',
    subLine: [
      'Free business finance comparison',
      'Exclusive business funding deals with banks and alternative lenders',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Business finance'
  },
  {
    parameter: 'working-capital',
    keyword: 'Working captial',
    title: 'Working captial',
    headline: 'Business Loans For Working Capital',
    subLine: [
      'Free business loan comparison',
      'Exclusive business funding deals with banks and alternative lenders',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Working capital'
  },
  {
    parameter: 'commercial-loan',
    keyword: 'Commercial loan',
    title: 'Commercial loan',
    headline: 'Compare commercial loans, hassle-free',
    subLine: [
      'Free commercial loan comparison with no credit check',
      'Exclusive commercial loan deals with banks and alternative lenders',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Commercial loans'
  },
  {
    parameter: 'think-business-loan',
    keyword: 'Think business loans',
    title: 'Think business loans',
    headline: 'Think Business Loans - compare business loans',
    subLine: [
      'Free business loans comparison with no credit check',
      'Exclusive business loan deals with banks and alternative lenders',
      'Speak to a loans specialist to help you every step of the way'
    ],
    proudct: 'Brand'
  },
  {
    parameter: 'comp',
    keyword: 'Competitors',
    title: 'Business loan',
    headline: 'Compare business loans from multiple lenders',
    subLine: [
      'Exclusive business loans deals with banks and alternative lenders',
      'Free business finance comparison with no credit check',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Competitors'
  },
  {
    parameter: 'asset-finance',
    keyword: 'Asset finance',
    title: 'Asset finance',
    headline: 'Compare asset finance, hassle-free',
    subLine: [
      'Exclusive asset finance deals with banks and alternative lenders',
      'Free asset finance comparison with no credit check',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Asset Finance'
  },
  {
    parameter: 'commercial-mortgages',
    keyword: 'Commercial mortagages',
    title: 'Commercial mortagages',
    headline: 'Compare commercial mortgages, hassle-free',
    subLine: [
      'Exclusive commercial mortgages with banks and alternative lenders',
      'Free commercial mortgage comparison with no credit check',
      'Speak to a finance specialist to help you every step of the way'
    ],
    proudct: 'Commercial mortgages'
  }

]
