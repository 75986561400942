<template>
  <div class="item-container" :class="itemType">
    <div class="item-image">
      <LazyImage :src="imgSrc" alt="" />
    </div>
    <div class="item-number">
      <h4 
        :class="[getPartner ? `${getPartner.className}__color` : '']"
      >
        {{ itemNumber }}
      </h4>
    </div>
    <div 
      :class="`item-title ${pageType}`"
    >
      <h4
        :class="getTextColour"
      >
        {{ itemTitle }} 
      </h4>
    </div>
    <div :class="`item-body ${pageType}`">
      <p>{{ itemText }}</p>
    </div>
  </div>
</template>

<script charset="utf-8">
import { mapGetters } from 'vuex'
import LazyImage from '@/components/performance/LazyImage'

export default {
  name: 'HowBionicProductWorksItem',
  components: {
    LazyImage
  },
  props: {
    imgSrc: {
      type: String,
      default: 'Lorem ipsum image source'
    },
    itemNumber: {
      type: String,
      default: 'Lorem ipsum number'
    },
    itemTitle: {
      type: String,
      default: 'Lorem ipsum title'
    },
    itemText: {
      type: String,
      default: 'Lorem ipsum text description'
    },
    itemType: {
      type: String,
      default: 'bionic-product-step-one'
    },
    pageType: {
      type: String,
      default: 'energy'
    }
  },
  computed: {
    ...mapGetters(['getPartner', 'getTextColour'])
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 2rem;
  padding-top: 80px;
  height: 100%;
  background-image: url('../../assets/images/dots-image.svg');
  background-repeat: no-repeat;

  &.bionic-product-step-one {
    background-position: 0% 0%;

    .item-title {
      padding-right: 0;
      &.energy {
        margin-bottom: 15px;
      }

    }
    .item-body {
      padding-right: 20px;
    }
  }

  &.bionic-product-step-two {
    background-position: 75% 0%;

    .item-title {
      &.energy {
        margin-bottom: 15px;
      }
    }
    .item-body {
      padding-right: 25px;
    }
  }

  &.bionic-product-step-three {
    background-position: 100% 0%;

    .item-title {
      &.energy {
        margin-bottom: 15px;
      }
    }

    .item-body {
      padding-right: 30px;
    }
  }

  &.referral {
    background-image: none;

    .item-image {
      img {
        width: 212px;
        height: 158px;
      }
    }
  }
}

.item-number {
  margin-bottom: 15px;
  width: 260px;

  h4 {
    color: $text-color-orange;
    font-size: 45px;
    font-weight: 900;
    line-height: 45px;
  }
}

.item-title {
  color: $main-title-color;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.03px;
  margin-bottom: 25px;
  width: 260px;

  h4 {
    font-weight: bold;
  }

  &.connectivity,
  &.insurance {
    flex: 1 1 auto;
  }
  &.broadband-promo {
    flex: 1;
  }
}

.item-image {
  position: relative;
  margin-bottom: 25px;
  width: fit-content;

  img {
    width: 265px;
    height: 252px;
  }
}

.item-body {
  flex-grow: 1;
  color: $text-color;
  width: 265px;
  &.connectivity {
    flex: 1;
  }
  &.insurance {
    flex: 1;
    flex-grow: 2;
  }
}

@media (max-width: $mobile-breakpoint-max) {

   .item-body,
   .item-title {
      width: auto;
  }

  .item-container {
    background-image: none;
    padding-top: 32px;
    align-items: flex-start;
    padding: 50px 0;

    &.bionic-product-step-one {
      background-position: 20% 0%;

      .item-title {
        margin-bottom: 0px;
      }

      .item-body {
        padding-right: 0;
      }
    }

    &.bionic-product-step-two {
      background-position: 60% 0%;

      .item-title {
        margin-bottom: 0px;
      }

      .item-body {
        padding-right: 0;
      }
    }

    &.bionic-product-step-three {
      background-position: 80% 0%;

      .item-title {
        margin-bottom: 0px;
      }

      .item-body {
        padding-right: 0;
      }
    }
    &.referral {
      padding-bottom: 0px;
      .item-image {
        margin-bottom: 0px;
      }
      .item-body {
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .item-number {
    margin-bottom: 8px;
    h4 {
      font-size: 45px;
    }
  }

  .item-title {
    margin-bottom: 12px;
  }

  .item-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
      height: unset;
    }
  }

}
</style>
