export const HERO_CONTENT_RECOVERY_LOANS = {
  title: 'Check your eligibility and compare Recovery Loans',
  sublines: [
    'Our experts <b>compare government backed Recovery Loan Scheme (RLS) lenders</b>',
    '<b>Speak to a Recovery Loan specialist</b> to help you every step of the way',
    'Compare and check your eligibility for <b>alternative finance options</b>'
  ]
}

export const HERO_CONTENT_ASSET_FINANCE = {
  title: 'Check your eligibility and compare Asset Finance',
  sublines: [
    '<b>Exclusive Asset Finance deals</b> with banks and alternative lenders',
    'Free Asset Finance comparison with <b>no credit check</b>',
    'Speak to an <b>Asset Finance specialist</b> to help you every step of the way'
  ]
}
