<template>
  <div>
    <NavbarJourney 
      v-if="getPartner"
      :telephone="getPhone" 
      :friendly-telephone="getFriendlyPhone"
      :class="{'gap-top' : getPartner }"
    />
    <div class="hero-section container-lg">
      <Navigation 
        v-if="!getPartner"
        :is-home="isHome" 
        :menu-items="menuItems"
      />

      <MobileNavigation 
        v-if="!getPartner" 
        :is-home="isHome"
        :menu-items="menuItems"
      />
      <div class="container hero-content animated">
        <div class="row">
          <div
            class="d-flex align-items-stretch col-lg-7 col-12"
          >
            <!-- eslint-disable vue/no-v-html -->
            <h1
              :class="['title', textColor]"
              v-html="textLineBreak(title)"
            />
          <!--eslint-enable-->
          </div>
        </div>
        <div class="row">
          <div
            class="hero-links col-lg-7 col-xl-7 col-12"
          >
            <div v-for="(item, i) in sublines" :key="i" class="row">
              <div class="hero-bullet-wrap col">
                <img src="@/assets/icons/tick-green.png" alt="green tick">
                <!-- eslint-disable vue/no-v-html -->
                <p 
                  class="bullet-text bullet-text--nw"
                  :class="textColor"
                  v-html="item"
                />
                <!--eslint-enable-->
              </div>
            </div>
          </div>
        </div>
        <div
          class="hero-form row"
        >
          <div class="col-lg-7 col-xl-7 col-12 col">
            <LoanInputHome :text-color="textColor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarJourney from '@/components/shared/NavbarJourney.vue'
import Navigation from '@/components/home/Navigation.vue'
import MobileNavigation from '@/components/home/MobileNavigation.vue'
import Routable from '@mic/frontend/mixins/Routable'
import LoanInputHome from '@/components/home/LoanInputHome'
import lineBreak from '@/helpers/formatting/lineBreak'
import { mapGetters } from 'vuex'

export default {
  name: 'HomepageHero',
  components: {
    Navigation,
    MobileNavigation,
    LoanInputHome,
    NavbarJourney
  },

  mixins: [Routable],

  props: {
    title: {
      type: String,
      default: 'Lorem Ipsum'
    },
    sublines: {
      type: Array,
      default: () => []
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    textColor: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['getPartner', 'getPhone', 'getFriendlyPhone', 'getTextColour']),
    isHome () {
      return this.onPage('home')
    }
  },

  methods: {
    questions () {
      this.$router.push('/question/amount')
    },
    textLineBreak (val) {
      return lineBreak(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-top {
  margin-bottom: 23px;
    @media(min-width: $md-width) {
        margin-bottom: 60px;
    }
}

.hero-form {
  @media (max-width: $md-width) {
    margin-top: 21px;
  }
}

.hero-section{
    padding: 0 !important;

  .hero-content {
    background: url("../../assets/images/hero-bg-small.png") no-repeat;
    background-size: contain, cover;
    background-position: 95% 100%;
    padding-bottom: 40px;

    .title {
      @media(max-width: $mobile-breakpoint-max) {
          font-size: 28px;
          line-height: 35px;
          margin-bottom: 30px;

      }

    }
  }
}

.title {
  color: $main-title-color;
  text-align: start;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 52px;
  margin-bottom: 30px;

}

.hero-bullet-wrap {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  img{
    margin-right: 10px;
    margin-top: 5px;
  }

  .bullet-text{
    margin: 0;
    width: 427px;
    text-align: start;
    color: $main-title-color;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;

    @media (max-width: $md-width)  {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }

    &--black {
      color: $black;
    }

    &--nw {
      width: auto;
    }
  }
}

.hero-button-wrapper{
  margin-top: 30px;
  button{
    height: 66px;
    width: 100%;
    border-radius: 33px;
    background-color: $text-color-orange;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      color: white;
    }
  }
}

.bionic-wrapper {
  display: flex;
  align-items: center;
  p {
    color: $main-title-color;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.11px;
    line-height: 19px;
    text-align: start;
    margin: 0%;
    width: 257px;
  }
  img {
    height: 41px;
    width: 35px;
    margin-right: 10px;
  }
}

@media (max-width: $md-width) {
  .hero-section {
    .hero-content {
      padding-top: 28px;
      background: none !important;
    }
  }

  .bionic-wrapper {
    justify-content: center;
    margin: 10px;
  }
}

@media (max-width: $xs-width) {
  .title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .hero-button-wrapper {
    margin-top: 0;
  }
}

</style>
