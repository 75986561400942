import getUrlParams from '@/helpers/urls/getUrlParams'
import { lpKeywords } from '@/keywords/lp-keywords'
import { LP } from '@/helpers/constants/journey-names'
const param = getUrlParams(LP)
// using params e.g ?lp=recovery

export default function () {
  // retur matching object parameter from url params
  let searchParam = lpKeywords.find(item => item.parameter === param)
  // if ?lp=test-word test word does not exisi in lpKeywords obj, use default null 
  if (!searchParam) searchParam = lpKeywords.find(item => item.parameter === null)
  return searchParam
}
