<template functional>
  <div>
    <div 
      ref="trustbox"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5989753e0000ff0005a86115"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
    > 
      <a href="https://uk.trustpilot.com/review/www.thinkbusinessloans.com" target="_blank" rel="noopener">Trustpilot</a> 
    </div>
  </div>
</template>
<script>
export default {
  name: 'TrustPilot',
  mounted () {
    let retry = 0
    const loadTrustbox = () => {
      retry++
      if (!window.Trustpilot && retry < 5) {
        return setTimeout(loadTrustbox, 100 * retry)
      } else {
        const trustbox = this.$refs.trustbox
        window.Trustpilot.loadFromElement(trustbox)
      }
    }

    loadTrustbox()
  }
}
</script>
